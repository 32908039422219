import Layout from '../components/layout/layout';
import React from 'react';
import styled from 'styled-components';
import { Fullscreen } from '../components/fullscreen/fullscreen';
import { Background } from '../components/background/background';

import cover from '../../static/images/contact/cover.png';
import { PRIMARY_BG_COLOR, PRIMARY_SUBTITLE_COLOR, PRIMARY_TITLE_COLOR, TEXT_FONT, TITLE_FONT } from '../constants';
import { Col, Container, Row } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { RandomGreeting } from '../components/contact/random-greeting';
import { useScrollingPage } from '../components/shared/scrolling-page.hook';
import { MAX_PAGES } from '.';

const Wrapper = styled.div`
    height: 100%;
    background-color: ${props => props.color};
    padding-top: 100px;
    padding-bottom: 100px;
    color: ${props => props.txtcolor};

    & > div {
        padding-top: 150px;
        padding-bottom: 150px;
        background-color: white;
    }

    p {
        font-family: ${TEXT_FONT};
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.1px;
    }

    h1 {
        font-family: ${TITLE_FONT};
        font-size: 56px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -2.8px;
        color: ${props => (props.txtcolor ? props.txtcolor : PRIMARY_TITLE_COLOR)};
        margin-bottom: 50px;
    }

    h3 {
        font-family: ${TEXT_FONT};
        font-size: 24px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        color: ${PRIMARY_TITLE_COLOR};
        margin-bottom: 20px;

        @media (max-width: 768px) {
            font-size: 20px;
        }
    }
`;

const WeWork = styled.div`
    font-family: ${TEXT_FONT};
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    color: ${PRIMARY_TITLE_COLOR};
    margin-top: 30px;

    @media (max-width: 768px) {
        font-size: 16px;
    }
`;

const StyledLink = styled.a`
    font-family: ${TEXT_FONT};
    display: block;
    font-size: 22px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: ${PRIMARY_TITLE_COLOR};

    @media (max-width: 768px) {
        font-size: 18px;
    }

    :hover {
        color: ${PRIMARY_SUBTITLE_COLOR};
    }
`;

const CardContainer = styled(Container)`
    background-color: ${props => props.color} !important;

    h2 {
        font-size: 22px;
    }

    @media (max-width: 768px) {
        margin-right: 5vw !important;
        margin-left: 5vw !important;
        width: 90vw !important;

        .m-space {
            margin-top: 60px;
        }
    }

    .space {
        margin-top: 60px;
    }

    .client {
        margin-right: 0;
        margin-left: 50%;
        color: white;
        background-color: #2f364a;
        float: right;
    }
`;

export default () => {
    const { canScroll, setCanScroll } = useScrollingPage(MAX_PAGES);
    return (
        <Layout setCanScroll={setCanScroll} canScroll={canScroll} bgColor={PRIMARY_BG_COLOR}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>equaleyes - Contact</title>
            </Helmet>
            <Fullscreen height="84vh" className="d-flex justify-content-center flex-column position-relative">
                <Background bg={cover} design={'cover'} index={1} />
                <RandomGreeting />
            </Fullscreen>
            <Wrapper color={'#212121'}>
                <CardContainer>
                    <Row>
                        <Col xs={12} xl={{ offset: 2, size: 8 }}>
                            <h1>Contact.</h1>
                        </Col>
                        <Col xs={12} md={6} xl={{ offset: 2, size: 4 }}>
                            <h3>
                                Let's see if <br /> we are a good fit.
                            </h3>
                        </Col>
                        <Col xs={12} md={6} xl={4} className={'m-space'}>
                            <h3>
                                Join Equaleyes. <br />
                            </h3>
                            <br />
                            <StyledLink href="tel:+386 1 518 66 81">+386 1 518 66 81</StyledLink>
                            <StyledLink href="mailto:contact@equaleyes.com">contact@equaleyes.com</StyledLink>
                            <WeWork className={'d-block d-md-none'}>We work from 9 till 17 CET time.</WeWork>
                        </Col>
                    </Row>
                </CardContainer>
            </Wrapper>
        </Layout>
    );
};
