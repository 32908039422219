import styled from 'styled-components';
import React, { useState, useCallback } from 'react';
import { Container } from 'reactstrap';
import { Transition } from 'react-spring';
import { PRIMARY_TEXT_COLOR, TITLE_FONT } from '../../constants';
import Typist from 'react-typist';

const Title = styled.h1`
    font-family: ${TITLE_FONT};
    font-size: 102px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.23;
    letter-spacing: -7.5px;
    color: ${PRIMARY_TEXT_COLOR};
    position: absolute;
    padding-left: 10px;

    border-right: 12px solid ${PRIMARY_TEXT_COLOR};
    animation: 500ms blink infinite step-start;

    @keyframes blink {
        from,
        to {
            border-right-color: transparent;
        }
        50% {
            border-right-color: ${PRIMARY_TEXT_COLOR};
        }
    }
`;

const TitleContainer = styled(Container)`
    z-index: 1;
`;

export const RandomGreeting = () => {
    const greetings = [
        [['Cheers.', 'Ahoy.']],
        [['Dober dan.', 'Živjo.']],
        [['Gutten tag.', 'Ello.']],
        [['Hey.', 'Hi.']],
        [['Nǐ hǎo.', 'Hi.']],
        [['Salut.', 'Ciào.']],
        [['Shalom.', 'Hola.']],
        [['How’s it going?', 'Ciào.']],
        [['Wotcha.', 'Ay-up.']],
        [['Grüß Gott.', 'Hoi.']],
        [['Wubba lubba dub dub!!', 'Yo!']],
        [["I'm Batman.", 'Sup.']],
    ];

    const [greeting, setGreeting] = useState(0);

    const nextGreeting = useCallback(() => {
        setTimeout(() => setGreeting((greeting + 1) % greetings.length), 500);
    }, [greeting, greetings.length]);

    return (
        <TitleContainer>
            <Transition
                native
                items={greetings[greeting]}
                from={{ transform: 'translate3d(0,0,0)', display: 'none' }}
                enter={{ transform: 'translate3d(0,0,0)', display: 'block' }}
                leave={{ transform: 'translate3d(0,0,0)', display: 'none' }}
            >
                {item => props => (
                    <div>
                        <Typist
                            cursor={{ show: false }}
                            className={'d-none d-md-block'}
                            onTypingDone={nextGreeting}
                            avgTypingDelay={75}
                            stdTypingDelay={50}
                        >
                            <Title>{item[0]}</Title>
                            <Typist.Backspace count={item[0].length} delay={1000} />
                        </Typist>
                        <Typist cursor={{ show: false }} className={'d-block d-md-none'}>
                            <Title>{item[1]}</Title>
                            <Typist.Backspace count={item[1].length} delay={1000} />
                        </Typist>
                    </div>
                )}
            </Transition>
        </TitleContainer>
    );
};
